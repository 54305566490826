import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import RedirectView from "@/views/RedirectView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView, // This is your main view, e.g., a URL generator
  },
  {
    path: "/fp/:id", // Route for Facebook posts
    name: "FacebookPost",
    component: RedirectView,
  },
  {
    path: "/fv/:id", // Route for Facebook videos
    name: "FacebookVideo",
    component: RedirectView,
  },
  {
    path: "/t/:id", // Route for Twitter
    name: "Twitter",
    component: RedirectView,
  },
  {
    path: "/:videoId", // Fallback route for YouTube and other non-prefixed platforms
    name: "GenericRedirect",
    component: RedirectView,
  },
  {
    path: "/i/:id", // Route for Instagram
    name: "Instagram",
    component: RedirectView,
  },
  {
    path: "/tt/:id", // Route for TikTok
    name: "TikTok",
    component: RedirectView,
  },
  {
    path: "/l/:id", // Route for LinkedIn
    name: "LinkedIn",
    component: RedirectView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
