<template>
  <div class="app">
    <header>
      <nav>
        <router-link to="/" class="logo">inapp.one</router-link>
      </nav>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <p>Made with ❤️ for a better mobile experience</p>
    </footer>
  </div>
</template>

<style>
/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.6;
  color: #333;
  background: #f8f9fa;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
}

nav {
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 24px;
  font-weight: 700;
  color: #dc2626;
  text-decoration: none;
  transition: color 0.3s ease;
}

.logo:hover {
  color: #b91c1c;
}

main {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

footer {
  text-align: center;
  padding: 1.5rem;
  background: white;
  color: #6c757d;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

footer p {
  font-size: 14px;
}

/* Responsive design */
@media (max-width: 768px) {
  main {
    padding: 1rem;
  }
}
</style>

<script>
export default {
  name: "App",
};
</script>
