<template>
  <div class="generator">
    <div class="input-group">
      <input
        ref="urlInput"
        v-model="inputUrl"
        type="text"
        placeholder="Paste your link here..."
        @input="convertUrl"
      />
      <button class="paste-button" @click="pasteFromClipboard">
        <span class="paste-icon">📋</span>
        <span class="paste-text">Paste</span>
      </button>
    </div>
    <div v-if="generatedUrl" class="output">
      <div class="output-header">Generated URL:</div>
      <div class="url-display">
        <a :href="generatedUrl" target="_blank">{{ generatedUrl }}</a>
      </div>
      <button class="copy-button" @click="copyToClipboard">
        <span class="copy-icon">📋</span>
        <span>Copy to Clipboard</span>
      </button>
      <p v-if="copySuccess" class="success">
        <span class="success-icon">✓</span> {{ copySuccess }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputUrl: "",
      generatedUrl: "",
      copySuccess: "",
    };
  },
  methods: {
    async pasteFromClipboard() {
      try {
        const text = await navigator.clipboard.readText();
        this.inputUrl = text;
        this.convertUrl();
      } catch (err) {
        console.error("Failed to read clipboard:", err);
      }
    },
    convertUrl() {
      const patterns = [
        {
          name: "YouTube (Short)",
          pattern: /https?:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})(?:\?.*)?/,
          generate: (id) => `${window.location.origin}/${id}`, // No prefix for YouTube
        },
        {
          name: "YouTube (Long)",
          pattern:
            /https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})(?:&.*)?/,
          generate: (id) => `${window.location.origin}/${id}`, // No prefix for YouTube
        },
        {
          name: "YouTube (No www)",
          pattern:
            /https?:\/\/youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})(?:&.*)?/,
          generate: (id) => `${window.location.origin}/${id}`, // No prefix for YouTube
        },
        {
          name: "YouTube (Embed)",
          pattern:
            /https?:\/\/(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})(?:\?.*)?/,
          generate: (id) => `${window.location.origin}/${id}`, // No prefix for YouTube
        },
        {
          name: "Facebook (Share Post)",
          pattern:
            /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:share\/p\/|posts\/|permalink\/)([a-zA-Z0-9_-]+)/,
          generate: (id) => `${window.location.origin}/fp/${id}`, // Prefix with /fp/
        },
        {
          name: "Facebook (Share Video)",
          pattern:
            /(?:https?:\/\/)?(?:www\.)?facebook\.com\/share\/v\/([a-zA-Z0-9_-]+)/,
          generate: (id) => `${window.location.origin}/fv/${id}`, // Prefix with /fv/
        },
        {
          name: "Twitter",
          pattern:
            /(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?(\w+)\/status\/(\d+)/,
          generate: (username, tweetId) =>
            `${window.location.origin}/t/${tweetId}`, // Prefix with /t/
        },
        {
          name: "Instagram Post",
          pattern:
            /(?:https?:\/\/)?(?:www\.)?instagram\.com\/p\/([a-zA-Z0-9_-]+)/,
          generate: (id) => `${window.location.origin}/i/${id}`, // Prefix with /i/
        },
        {
          name: "Instagram Reel",
          pattern:
            /(?:https?:\/\/)?(?:www\.)?instagram\.com\/reel\/([a-zA-Z0-9_-]+)/,
          generate: (id) => `${window.location.origin}/i/${id}`, // Use same prefix for reels
        },
        {
          name: "TikTok (Short URL)",
          pattern: /(?:https?:\/\/)?(?:vt\.tiktok\.com\/)([A-Za-z0-9]+)/,
          generate: (id) => `${window.location.origin}/tt/${id}`,
        },
        {
          name: "TikTok",
          pattern:
            /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@[\w.-]+\/video\/(\d+)/,
          generate: (id) => `${window.location.origin}/tt/${id}`,
        },
        {
          name: "LinkedIn Post",
          pattern:
            /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/posts\/[\w-]+_[\w-]+-(\d+)-[\w-]+/,
          generate: (id) => `${window.location.origin}/l/${id}`, // Prefix with /l/
        },
        // Add more platform patterns with appropriate prefixes as needed
      ];

      let matchFound = false;
      for (const platform of patterns) {
        const match = this.inputUrl.match(platform.pattern);
        if (match) {
          this.generatedUrl = platform.generate(...match.slice(1));
          matchFound = true;
          break;
        }
      }

      if (!matchFound) {
        this.generatedUrl = "";
      }
    },
  },
};
</script>

<style scoped>
.generator {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.input-group {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 20px;
  background: white;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
}

input {
  flex: 1;
  padding: 16px;
  font-size: 16px;
  border: 2px solid #eef2ff;
  border-radius: 12px;
  transition: all 0.3s ease;
  background: #f8faff;
  color: #2d3748;
}

input:focus {
  outline: none;
  border-color: #ef4444;
  background: white;
  box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.1);
}

input::placeholder {
  color: #a0aec0;
}

.paste-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  font-size: 16px;
  background: #fee2e2;
  border: none;
  border-radius: 12px;
  color: #dc2626;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.paste-button:hover {
  background: #fecaca;
  transform: translateY(-1px);
}

.paste-icon {
  font-size: 18px;
}

.output {
  background: white;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.output-header {
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.url-display {
  background: #fff5f5;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 2px solid #fee2e2;
}

.url-display a {
  color: #dc2626;
  text-decoration: none;
  word-break: break-all;
  font-size: 15px;
  transition: color 0.2s ease;
}

.url-display a:hover {
  color: #b91c1c;
  text-decoration: underline;
}

.copy-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 14px;
  background: #dc2626;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  justify-content: center;
}

.copy-button:hover {
  background: #b91c1c;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(220, 38, 38, 0.3);
}

.copy-button:active {
  transform: translateY(0);
}

.success {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #059669;
  margin-top: 12px;
  font-size: 14px;
  animation: fadeIn 0.3s ease;
}

.success-icon {
  font-size: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 480px) {
  .input-group {
    flex-direction: column;
    gap: 8px;
  }

  .paste-button {
    width: 100%;
    justify-content: center;
  }
}
</style>
