<template>
  <div class="redirect">
    <div class="content">
      <div class="loader"></div>
      <div class="message">
        <p>Opening in native app...</p>
        <p class="subtitle">
          Generated with
          <a href="/" class="brand-link">inapp.one</a>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.redirect {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f9fa;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.content {
  width: 90%;
  max-width: 600px;
  text-align: center;
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 1rem;
}

.message {
  margin-top: 1.5rem;
}

.message p {
  font-size: 18px;
  color: #2d3748;
  margin: 0.5rem 0;
}

.subtitle {
  font-size: 16px !important;
  color: #6c757d !important;
}

strong {
  color: #dc2626;
  font-weight: 600;
}

.loader {
  width: 48px;
  height: 48px;
  border: 4px solid #fee2e2;
  border-top: 4px solid #dc2626;
  border-radius: 50%;
  margin: 0 auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile Responsiveness */
@media (max-width: 480px) {
  .content {
    padding: 1.5rem;
  }

  .message p {
    font-size: 16px;
  }

  .subtitle {
    font-size: 14px !important;
  }
}

.brand-link {
  color: #dc2626;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s ease;
}

.brand-link:hover {
  color: #b91c1c;
  text-decoration: underline;
}
</style>

<script>
export default {
  name: "RedirectView",
  mounted() {
    const { id, videoId } = this.$route.params;
    const urlPath = id || videoId; // Use id for prefixed routes or videoId for generic routes

    // Get the current route path safely
    const currentPath = this.$route.path || "";

    // Define platform-specific redirection rules
    const platformPatterns = [
      {
        name: "YouTube",
        prefix: "",
        pattern: /^[a-zA-Z0-9_-]{11}$/, // Matches YouTube video IDs
        redirect: (id) => `youtube://watch?v=${id}`,
      },
      {
        name: "Facebook Post",
        prefix: "/fp/",
        pattern: /^[a-zA-Z0-9_-]+$/, // Matches Facebook post IDs
        redirect: (id) => `fb://post/${id}`,
      },
      {
        name: "Facebook Video",
        prefix: "/fv/",
        pattern: /^[a-zA-Z0-9_-]+$/, // Matches Facebook video IDs
        redirect: (id) => `fb://video/${id}`,
      },
      {
        name: "Twitter",
        prefix: "/t/",
        pattern: /^(\d+)$/, // Matches Twitter status IDs
        redirect: (id) => `twitter://status?id=${id}`,
      },
      {
        name: "Instagram",
        prefix: "/i/",
        pattern: /^[a-zA-Z0-9_-]+$/, // Matches Instagram post/reel IDs
        redirect: (id) => `instagram://media?id=${id}`,
      },
      {
        name: "TikTok (Short)",
        prefix: "/tt/",
        pattern: /^[A-Za-z0-9]+$/, // Matches TikTok short URL IDs
        redirect: (id) => `tiktok://video/${id}`,
      },
      {
        name: "TikTok",
        prefix: "/tt/",
        pattern: /^(\d+)$/, // Matches TikTok video IDs
        redirect: (id) => `tiktok://video/${id}`,
      },
      {
        name: "LinkedIn",
        prefix: "/l/",
        pattern: /^(\d+)$/, // Matches LinkedIn post IDs
        redirect: (id) => `linkedin://post/${id}`,
      },
      // Additional patterns for expanded social platforms
      {
        name: "Facebook Profile",
        prefix: "/f/",
        pattern: /^(\d+)$/, // Matches Facebook profile IDs
        redirect: (id) => `fb://profile/${id}`,
      },
      {
        name: "Facebook Group",
        prefix: "/fg/",
        pattern: /^([^/]+)$/, // Matches Facebook group IDs
        redirect: (id) => `fb://group/${id}`,
      },
      {
        name: "Twitter User",
        prefix: "/tu/",
        pattern: /^([\w]+)$/, // Matches Twitter usernames
        redirect: (username) => `twitter://user?screen_name=${username}`,
      },
      {
        name: "Instagram Story",
        prefix: "/is/",
        pattern: /^(\d+)$/, // Matches Instagram story IDs
        redirect: (id) => `instagram://story?id=${id}`,
      },
      {
        name: "Reddit",
        prefix: "/r/",
        pattern: /^([a-zA-Z0-9_-]+)$/, // Matches Reddit post IDs
        redirect: (id) => `reddit://post/${id}`,
      },
      {
        name: "Reddit Subreddit",
        prefix: "/rs/",
        pattern: /^([a-zA-Z0-9_-]+)$/, // Matches subreddit names
        redirect: (name) => `reddit://subreddit/${name}`,
      },
      {
        name: "Pinterest",
        prefix: "/p/",
        pattern: /^(\d+)$/, // Matches Pinterest pin IDs
        redirect: (id) => `pinterest://pin/${id}`,
      },
      {
        name: "Spotify Track",
        prefix: "/s/",
        pattern: /^([a-zA-Z0-9]+)$/, // Matches Spotify track IDs
        redirect: (id) => `spotify://track/${id}`,
      },
      {
        name: "Spotify Playlist",
        prefix: "/sp/",
        pattern: /^([a-zA-Z0-9]+)$/, // Matches Spotify playlist IDs
        redirect: (id) => `spotify://playlist/${id}`,
      },
    ];

    let redirected = false;

    for (const platform of platformPatterns) {
      if (platform.prefix && currentPath.startsWith(platform.prefix)) {
        // Direct handling for prefixed routes
        if (platform.pattern.test(urlPath)) {
          window.location.href = platform.redirect(urlPath);
          redirected = true;
          break;
        }
      } else if (!platform.prefix && platform.pattern.test(urlPath)) {
        // Handle platforms without a prefix (e.g., YouTube)
        window.location.href = platform.redirect(urlPath);
        redirected = true;
        break;
      }
    }

    if (!redirected) {
      console.warn("No matching platform pattern found for:", urlPath);
    }
  },
};
</script>
