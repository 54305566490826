<template>
  <div class="home">
    <div class="content">
      <h1>Direct App Link Generator</h1>
      <p class="description">
        Generate links that open directly in native apps instead of in-app
        browsers
      </p>
      <URLGenerator />
    </div>
  </div>
</template>

<style scoped>
.home {
  width: 100%;
  max-width: 100vw;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.description {
  color: #6c757d;
  margin-bottom: 24px;
  font-size: 16px;
}
</style>

<script>
import URLGenerator from "@/components/URLGenerator.vue";

export default {
  name: "HomeView",
  components: {
    URLGenerator,
  },
};
</script>
